<template>
  <div class="lg:my-20">
    <div class="flex flex-row sm:flex-col justify-center" v-if="isShowHeader">
      <div class="mx-auto w-full max-w-lg">
        <div class="flex w-full justify-between items-center">
          <div class="flex w-full">
            <div class="w-full" v-for="item in tabs">
              <NuxtLinkLocale :to="checkAndAddSlash(item.link)">
                <div class="flex items-center justify-center py-3 px-3 font-medium border-b-2 bg-white" :class="active === item.link ? 'text-secondary-500 border-secondary' : 'text-gray-400'">
                  <span class="text-base md:text-base whitespace-nowrap">
                      {{$t(item.title)}}
                  </span>
                </div>
              </NuxtLinkLocale>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="flex justify-center">
      <div class="flex-1 flex flex-col justify-center items-center max-w-lg shadow-lg mx-2 sm:mx-0 p-4 sm:p-0 bg-white">
        <slot />
      </div>
    </div>
  </div>
</template>

<script setup>
const props = defineProps(['active'])

const storeConfig = useStoreConfig()
const { accessType, registrationConfig, storeType } = storeToRefs(storeConfig)

const isShowHeader = computed(() => (storeType.value === 'b2b' && registrationConfig.value?.allow_direct_register_for_b2b) || (storeType.value !== 'b2b' && !registrationConfig.value?.allow_direct_register_for_b2b))

const tabs = ref([
    { title: "auth.sign-in", id: "1", link: "login" }
]);

if (unref(accessType) !== 'b2b') {
    tabs.value.push({ title: "auth.sign-up", id: "2", link: "register" })
}

useServerSeoMeta({
  robots: 'noindex, nofollow'
})

</script>

<style scoped>

</style>